import {
  FaDiscord,
  FaMedium,
  FaTelegram,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const socialLinks = [
  { name: "twitter", link: "https://twitter.com/GalacticaNet" },
  { name: "discord", link: "https://discord.gg/galactica" },
  { name: "youtube", link: "https://www.youtube.com/@GalacticaNetwork" },
  { name: "telegram", link: "https://t.me/TheCypherState" },
  { name: "medium", link: "https://medium.com/galactica-network" },
];

export const Mobile = () => {
  return (
    <div
      className="flex h-screen w-screen flex-col items-center justify-center overflow-hidden bg-cover bg-center bg-no-repeat px-[16px]"
      style={{ backgroundImage: `url('/bg-mobile.png')` }}
    >
      <img className="mb-[16px] w-[278px]" src="/gala-logo-mobile.png" />
      <div className="mb-[40px] max-w-[400px] text-center">
        <h2 className="mb-[16px] text-[32px] font-semibold leading-[38px] text-balticSea ">
          Experience Galactica Fully on Desktop
        </h2>
        <p className="mb-[16px] text-[16px] text-balticSea/75">
          It looks like you’re using a mobile device. For now, please switch to
          a desktop to continue using Galactica, as MetaMask snaps are
          desktop-only.
        </p>
        <p className="text-[16px] text-balticSea/75">
          When you’re ready, we’ll be here to guide you through the full
          experience!
        </p>
      </div>
      <SocialLinks links={socialLinks} />
    </div>
  );
};

interface SocialLink {
  link: string;
  name: string;
}

interface SocialLinksProps {
  links: SocialLink[];
}

const SocialLinks = ({ links }: SocialLinksProps) => {
  const iconMap: { [key: string]: React.ReactElement } = {
    twitter: <FaTwitter />,
    discord: <FaDiscord />,
    youtube: <FaYoutube />,
    telegram: <FaTelegram />,
    medium: <FaMedium />,
  };

  return (
    <div className="flex space-x-[14px]">
      {links.map((social) => {
        const Icon = iconMap[social.name.toLowerCase()];
        return (
          <a
            className="flex size-[33px] items-center justify-center rounded-[5px] border border-black/10 bg-white text-[#ACAEB2] transition-colors"
            href={social.link}
            key={social.name}
            rel="noopener noreferrer"
            target="_blank"
          >
            {Icon}
          </a>
        );
      })}
    </div>
  );
};
