import { ComponentProps, ReactNode } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { twMerge } from "tailwind-merge";
import {
  ConnectorAlreadyConnectedError,
  useAccount,
  useChainId,
  useConnect,
  useDisconnect,
  useSwitchChain,
} from "wagmi";
import { getWalletClientQueryOptions } from "wagmi/query";

import { wagmiConfig } from "shared/providers/wagmi";
import { useGetSnapQuery, useInstallSnapMutation } from "shared/snap/rq";
import { useClaimrAuthStatusStore } from "shared/stores";
import { ClassName } from "shared/types";
import { Button, ButtonProps } from "shared/ui/button";
import { catchError } from "shared/ui/toast";
import { claimrConnect } from "shared/utils";
import { shortAddress } from "shared/web3/utils";

type Props = {
  connectContent?: ReactNode;
  installMetamaskContent?: ReactNode;
  installSnapContent?: ReactNode;
  switchChainContent?: ReactNode;
} & ButtonProps &
  ClassName;

export function ConnectButton({
  className,
  installMetamaskContent = "Install MetaMask",
  connectContent = "Connect MetaMask",
  switchChainContent = "Switch to Galactica",
  installSnapContent = "Install MetaMask SNAP",
  ...props
}: Props) {
  const queryClient = useQueryClient();
  const { address, isDisconnected, isConnecting, connector, chain } =
    useAccount();
  const chainId = useChainId();

  const snapQuery = useGetSnapQuery();
  const installSnapMutation = useInstallSnapMutation();

  const { switchChain, isPending: isSwitchChainPending } = useSwitchChain();
  const { disconnect, isPending: isDisconnectPending } = useDisconnect();
  const { connectAsync, connectors } = useConnect();
  const [claimrAuthStatus, setClaimrAuthStatus] = useClaimrAuthStatusStore();

  const handleConnect = async () => {
    const metamaskConnector = connectors.find(
      (connector) => connector.name === "MetaMask"
    );
    const flaskConnector = connectors.find((connector) => {
      return connector.name === "MetaMask Flask";
    });

    const connector = flaskConnector ?? metamaskConnector;

    try {
      if (!connector)
        throw new Error("Install MetaMask or MetaMask flask wallets");
      const data = await connectAsync({ connector });
      const address = data.accounts[0];

      if (claimrAuthStatus === "not-auth") {
        const wc = await queryClient.fetchQuery(
          getWalletClientQueryOptions(wagmiConfig, {
            account: address,
            chainId,
          })
        );

        const message = `
        Sign this message to prove you have access to this wallet and we’ll log you in.
        This won’t cost you any GNET.
  
        URI:
        https://galactica.com/
  
        Nonce:
        g-1VRwqbOocmkDjT
  
        Issued At:
        2024-09-17T11:00:11.103Z
        `;

        const signature = await wc.signMessage({
          message: message,
          account: address,
        });

        claimrConnect(address, signature, message);
        setClaimrAuthStatus("auth");
      }
    } catch (error) {
      disconnect();
      if (!(error instanceof ConnectorAlreadyConnectedError)) {
        catchError(error);
      }
    }
  };

  const btnProps: ComponentProps<typeof Button> = {
    className: twMerge("w-[18.75rem] gap-x-1.5 whitespace-nowrap", className),
    isLoading: isConnecting,
    ...props,
  };

  if (
    !connectors.some(
      (connector) =>
        connector.name === "MetaMask" || connector.name === "MetaMask Flask"
    )
  ) {
    return (
      <Button
        as="a"
        href="https://metamask.io/download"
        target="_blank"
        {...btnProps}
      >
        {installMetamaskContent}
      </Button>
    );
  }

  if (isDisconnected || isConnecting || claimrAuthStatus == "not-auth") {
    return (
      <Button {...btnProps} isLoading={isConnecting} onClick={handleConnect}>
        {connectContent}
      </Button>
    );
  }

  if (!chain) {
    return (
      <Button
        {...btnProps}
        isLoading={isSwitchChainPending}
        onClick={() => {
          switchChain(
            { connector: connector, chainId },
            {
              onError: (error) => {
                catchError(error);
              },
            }
          );
        }}
      >
        {switchChainContent}
      </Button>
    );
  }

  if (snapQuery.isSuccess && !snapQuery.data) {
    return (
      <Button
        {...btnProps}
        isLoading={installSnapMutation.isPending}
        onClick={() => {
          installSnapMutation.mutate({});
        }}
      >
        {installSnapContent}
      </Button>
    );
  }

  return (
    <Button
      {...btnProps}
      isLoading={isDisconnectPending}
      onClick={() => disconnect()}
    >
      {shortAddress(address, 4, 4)}
    </Button>
  );
}
