import { Chain } from "viem";

import { galacticaAndromeda } from "./networks/galactica-andromeda";
import { galacticaReticulumTestnet } from "./networks/galactica-reticulum";

export const supportedChains = [
  galacticaAndromeda,
  galacticaReticulumTestnet,
].filter(
  (chain) => chain.id === Number.parseInt(import.meta.env.VITE_CHAIN_ID)
) as [Chain, ...Chain[]];

console.log(window.location.host);

export const links = {
  [galacticaAndromeda.id]: {
    sampleKYCProvider: "https://kyc-andromeda.galactica.com",
    swissborgKYCProvider: "https://stage-swissborg.galactica.com",
    occamDataGuardian: "https://stage-guardian.occam.fi",
  },
  [galacticaReticulumTestnet.id]: {
    sampleKYCProvider: window.location.host.includes("starbase")
      ? "https://kyc-starbase.galactica.com"
      : "https://kyc-reticulum.galactica.com",
    swissborgKYCProvider: "https://swissborg.galactica.com",
    occamDataGuardian: "https://guardian.occam.fi",
  },
};

export const featuresFlags = {
  [galacticaAndromeda.id.toString()]: {
    proofs: true,
  },
  [galacticaReticulumTestnet.id.toString()]: {
    proofs: true,
  },
} as const;
