import { useEffect } from "react";

import { useAuthStatus } from "widget/auth";

import { claimrCompleteTask } from "shared/utils";

export const useCompleteQuests = () => {
  const { isSnapAuth, isMetamaskAuth } = useAuthStatus({
    isSnapNeeded: true,
    isMetamaskNeeded: true,
  });

  useEffect(() => {
    if (isSnapAuth) {
      claimrCompleteTask("installSnap");
    }
  }, [isSnapAuth]);

  useEffect(() => {
    if (isMetamaskAuth) {
      claimrCompleteTask("join");
    }
  }, [isMetamaskAuth]);
};
