import { useEffect } from "react";

import { Chain, Transport } from "viem";
import { Config, useConfig } from "wagmi";
import { GetAccountReturnType, watchAccount } from "wagmi/actions";

import { useEventCallback } from "shared/hooks";

type Account = GetAccountReturnType<
  Config<[Chain, ...Chain[]], Record<number, Transport>>
>;

export const useChangeAccountEffect = (
  cb: (account: Account, prevAccount: Account) => void
) => {
  const config = useConfig();
  const changeEvent = useEventCallback(cb);
  useEffect(() => {
    const unwatch = watchAccount(config, {
      onChange: changeEvent,
    });

    return () => unwatch();
  }, [config, changeEvent]);
};
