import { PropsWithChildren, Suspense } from "react";
import { Outlet } from "react-router-dom";

import { useHover, useLocalStorage } from "@uidotdev/usehooks";
import { AnimatePresence, motion } from "framer-motion";
import { twJoin, twMerge } from "tailwind-merge";

import { Welcome } from "pages/welcome/welcome";
import { Spinner } from "shared/ui/spinner";

import { Sidebar } from "./sidebar/sidebar";

type Props = {
  isDrawer?: boolean;
};

export const Layout = ({ isDrawer, children }: PropsWithChildren<Props>) => {
  const [ref, hovering] = useHover();
  const isExpanded = !isDrawer || hovering;
  const [hideIntroduction] = useLocalStorage("hideIntroduction", false);

  const isDev = import.meta.env.VITE_DEV === "true";

  return (
    <>
      {isDev && (
        <div
          className="absolute left-0 top-0 z-[100] flex h-[32px] w-full items-center justify-center text-center text-[14px] font-semibold text-white"
          style={{
            background: "linear-gradient(45deg, #F26F56 0%, #F49756 100%)",
          }}
        >
          <span>THIS IS A TESTING ENVIRONMENT – </span>
          <a
            className="ml-[4px] underline"
            href="http://app.galactica.com/"
            rel="noreferrer"
            target="_blank"
          >
            SWITCH TO PROD
          </a>
        </div>
      )}

      <div
        className={twMerge(
          "grid grow grid-cols-[auto,1fr] grid-rows-1 grid-areas-layout",
          isDev && "relative pt-[32px]"
        )}
      >
        <Sidebar
          className={isDev ? "top-[32px]" : ""}
          drawer={isDrawer}
          drawerRef={ref}
          isExpanded={isExpanded}
          // onToggleExpand={setExpanded}
        />
        <main
          className={twJoin(
            "relative z-0 flex flex-col bg-aquaHaze grid-in-main",
            isDrawer && "pl-20"
          )}
        >
          {isDrawer && (
            <AnimatePresence>
              {isExpanded && (
                <motion.div
                  animate={{ opacity: 1 }}
                  className="absolute inset-0 z-50 grow bg-black/50"
                  exit={{ opacity: 0 }}
                  initial={{ opacity: 0 }}
                />
              )}
            </AnimatePresence>
          )}
          {!hideIntroduction && <Welcome />}
          <Suspense
            fallback={
              <div className="flex grow items-center justify-center">
                <Spinner />
              </div>
            }
          >
            {children ?? <Outlet />}
          </Suspense>
        </main>
      </div>
    </>
  );
};
